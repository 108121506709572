import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import SharedForm from './SharedForm';

function SharedDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
    >
      <div className="relative w-full flex justify-end cursor-pointer" onClick={() => props.handleClose()}>
        <CloseIcon className="text-gray-700 mr-2 mt-2"/>
      </div>
      <DialogTitle>
        <div className="font-poppins font-bold text-pink-500 text-2xl leading-realxed text-center">
          ¿Necesitas más tiempo para pensarlo?
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="text-gray-800 text-base leading-realxed">
        Sabemos que proteger a tus seres queridos es una decisión importante. Por eso podemos <span className="font-bold">enviarte una copia de esta cotización junto con información detallada de los servicios y beneficios</span> para que puedas descargarla y revisarla luego o discutirla con tus familiares.
        </div>
        <div className="text-gray-800 text-base leading-realxed my-4">
          Es tan fácil como decirnos a cuál correo quieres que te lo enviémos:
        </div>
        <SharedForm history={props.history} handleClose={props.handleClose} />
      </DialogContent>
    </Dialog>
  )
}

SharedDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog({breakpoint: 'xs'})(SharedDialog);
