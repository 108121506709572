export default [
  {
    value: "BARCELONA", 
    name: "Barcelona",
  },
  {
    value: "BARQUISIMETO", 
    name: "Barquisimeto",
  },
  {
    value: "CAGUA", 
    name: "Cagua",
  },
  {
    value: "CARACAS", 
    name: "Caracas",
  },
  {
    value: "CHARALLAVE", 
    name: "Charallave",
  },
  {
    value: "GUACARA", 
    name: "Guacara",
  },
  {
    value: "GUARENAS", 
    name: "Guarenas",
  },
  {
    value: "GUATIRE", 
    name: "Guatire",
  },
  {
    value: "LA VICTORIA", 
    name: "La Victoria",
  },
  {
    value: "LECHERÍA", 
    name: "Lechería",
  },
  {
    value: "LOS TEQUES", 
    name: "Los Teques",
  },
  {
    value: "MARACAIBO - SAN FRANCISCO", 
    name: "Maracaibo - San Francisco",
  },
  {
    value: "MARACAY", 
    name: "Maracay",
  },
  {
    value: 'PAMPATAR',
    name: 'Pampatar'
  },
  {
    value: 'PORLAMAR',
    name: 'Porlamar'
  },
  {
    value: "PUERTO LA CRUZ", 
    name: "Puerto la Cruz",
  },
  {
    value: "PUERTO ORDAZ - SAN FELIX",
    name: "Puerto Ordaz - San Felix",
  },
  {
    value: "SAN ANTONIO DE LOS ALTOS", 
    name: "San Antonio de los Altos",
  },
  {
    value: "SAN CRISTÓBAL", 
    name: "San Cristóbal",
  },
  {
    value: "TURMERO", 
    name: "Turmero",
  },
  {
    value: "VALENCIA", 
    name: "Valencia",
  },
  {
    value: "VARGAS", 
    name: "Vargas",
  },
]