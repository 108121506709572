import React from 'react';
import {FormControlLabel, Radio} from '@material-ui/core';

export default function PaymentFrecuency({
  frecuency,
  intervals,
  percent_off,
  formatFloat,
  selectedInterval,
  handleSelectInterval,
  isoCode
}){

  const sortedIntervals = [
    'year',
    'semiannual',
    'quarterly',
    'month',
  ]

  return (
    <div className="w-full">
      <div className="container">
      <div className={`w-full flex flex-col mt-10 px-6 sm:grid sm:grid-cols-2 sm:gap-x-4 md:grid-flow-col md:grid-cols-${Object.keys(intervals).filter((i)=> typeof intervals[i] === 'object').length}`}> 
        {typeof intervals === 'object' 
          &&  Object.keys(intervals)
            .sort((a, b)=>(sortedIntervals.indexOf(a) - sortedIntervals.indexOf(b)))
            .map((interval, index)=>{
              if(typeof intervals[interval] !== 'object') return
              return (
                  <Plan 
                    key={index}
                    selected={selectedInterval?.total === intervals[interval]?.total ? true :  false} 
                    save={intervals[interval]?.percentage_saving} 
                    interval={frecuency(interval)}
                    cost={formatFloat(parseFloat(intervals[interval]?.monthly_price))}
                    handleClick={()=>handleSelectInterval(intervals[interval])}
                    isoCode={isoCode}
                  />          
                )
            })}
        </div>    

        {selectedInterval.total && (
          <div className="grid place-items-center gap-y-1 mx-auto mt-12 mb-8 w-full">
            <span className="font-bold text-purple-500 text-lg flex mb-2">
              Total a pagar hoy:
            </span>
            <span className="text-xl md:text-2xl flex items-center">
              {((selectedInterval?.promotion?.apply_promotion && selectedInterval?.promotion?.percent_off > 0) || percent_off > 0 ) 
                ? (
                  <span className="text-gray-700">
                    <s>
                      {formatFloat(selectedInterval?.total)}
                    </s>
                  </span>
                )
                : null
              } 
              <span className="text-center uppercase text-pink-500 font-bold">
                  &nbsp; {isoCode.toUpperCase()}.{parseFloat(selectedInterval?.total - ((selectedInterval?.total * (selectedInterval?.promotion?.percent_off || percent_off )) / 100)).toFixed(2)}
              </span>
              <span className="text-base text-gray-800">
                /{frecuency(selectedInterval?.recurrent_interval)}
              </span>
            </span>
            { selectedInterval?.percentage_saving <= 0 
              ? null 
              : ( <div className="bg-white p-2 rounded-xl border border-purple-300 text-center">
                    <span className="text-purple-300 px-12">Ahorras <b>{isoCode.toUpperCase()}.{selectedInterval?.yearly_saving}</b> al año</span>
                  </div> )
            }
          </div>
        )}
      
      </div>

      <div className="w-full flex justify-center items-center mt-8 text-sm text-purple-500 leading-relaxed text-center font-bold">
          Por favor tener en cuenta que esta cotización puede variar al momento de la afiliación ya que el precio definitivo dependerá de la edad actuarial.
      </div> 
    </div>
  )
  
}

function Plan({selected, save, interval, cost, handleClick,isoCode}){
  const gradient = "bg-gradient-to-r from-orange-500 via-orange-600 to-pink-700"

  return (
    <div className="grid gap-y-2 place-items-end mt-10 md:mt-0 w-full">

      <div className={`py-2 flex items-center justify-center border border-gray-300 w-full rounded-mdmb-1 place-self-start ${save <= 0? "hidden" : "inline"}`}>
        <span className={`font-bold text-pink-500 text-center `}>
          Ahorra {save}%
        </span>
      </div>

      <FormControlLabel
        className="m-0 w-full"
        name="payment_frecuency"
        control={
          <div
          className={`border border-gray-300 delay-100 duration-100 transition ${selected ? gradient : null} rounded-md ${selected ?'text-white' : 'text-gray-800'} grid place-items-center pt-6 pb-3 w-full`}
          >
            <span className="font-bold text-lg block text-center">
              {interval === 'Anual' ? 'Anual (recomendado)' : interval}
              </span>
            <span className={`font-bold sm:text-xl md:text-2xl lg:text-3xl mt-4 ${selected ? 'text-white': 'text-purple-500'}`}>
              {isoCode.toUpperCase()}.{cost}
            </span>
            <span className="mb-4">/mes</span>
            <Radio
              name={interval}
              className={`${selected ? 'text-white': 'text-gray-800'}`}
              onClick={handleClick}
              checked={selected}
            />
          </div>
        }
      />
    </div>
  )
}
