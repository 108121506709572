import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Radio, FormControlLabel, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Router from 'next/router';
import TagManager from 'react-gtm-module';

import SharedDialog from 'components/quotation/SharedDialog';
import { SnackBar } from 'components/widgets';
import { isArray } from 'util';
import { DateInput, SelectCurrency } from 'components/commons';
import { format } from 'components/utils/Date';
import IdleTimer from '../quotation/IdleTimer';
import PaymentFrecuency from './PaymentFrecuency';
class QuotationBeneficiaries extends Component {
  timer = null;

  state = {
    total: 0,
    selectedInterval: {},
    openAlert: false,
    messageAlert: '',
    quotation: this.props.quotation || [],
    intervals: [],
    method: {},
    promotion: null,
    openDialog: false,
    lastLocation: null,
    confirmedNavigation: false,
    percent_off: 0,
    eventSended: false,
    date: null,
    allIntervals: {}
  }

  async componentDidMount() {
    let method = [];
    let quotation = this.props.quotation;

    if(this.props.payment_methods) {
      method = this.props.payment_methods.find((m) => m.default === true);
      this.setState({ method: method || this.props.payment_methods[0] })
    }

    // Seleccionar el primer plan por defecto para calcular el precio inical

    if(method) 
      quotation.map((q, index) => this.handleSelect(index, method?.plans[0]._id, method?.plans[0]));
      
    await this.props.handleSave('quotation', quotation);
    await this.handleSubmit()
    /**
     * Escuchar el cambio de ruta
    */
    Router.onRouteChangeStart = async (url) => {
      const { confirmedNavigation } = this.state;
      let quotation = this.props.quotation;

      if(!quotation.find((b) => (!b.product_id))) {
        if (
          !confirmedNavigation &&
          url !== '/compartir-cotizacion' &&
          url !== '/comenzar-afiliacion' &&
          url !== '/registro-cliente-opcional' &&
          url !== '/resultado-cotizacion'
        ) {
          this.setState({ confirmedNavigation: true }, async ()=>{
            await this.showDialog(url)
            if (this.timer) this.stopTimer()
            Router.router.abortComponentLoad();
          })
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(Object.keys(this.state.allIntervals).length>0) {
      if(nextProps.isoCode!==this.props.isoCode) {
        this.setState({intervals: this.state.allIntervals[nextProps.isoCode]});
        this.handleSelectInterval(this.state.allIntervals[nextProps.isoCode][this.state.selectedInterval.recurrent_interval || "year"]);
      }
    }
  }

  async componentWillUnmount() {
    /* Stop and remove the timer */
    await this.stopTimer();
        
    /**
     * Cambiar la funcion de escucha a null para detener el comportamiento
     */
    Router.onRouteChangeStart = null;
  }

  startTimer = () => {
    if(this.timer){
      this.stopTimer()
    }
    this.timer = new IdleTimer({
      timeout: 20, // expire after 20 seconds
      onTimeout: () => {
        this.setState({openDialog: true});
      },
    });
  }

  stopTimer = () => {
    if (this.timer && this.timer.cleanUp) {
      this.timer = this.timer.cleanUp()
    }
  }

  structure = (quotation, products) => {
    return {
      birth_date: null,
      country: quotation[0].country,
      city: quotation[0].city,
      products,
      product_id: '',
      product_type: {},
      questions: [],
      price: 0
    }
  }

  frecuency(param) {
    if (param === 'month') {
      return 'Mensual'
    } else if (param === 'year') {
      return 'Anual'
    } else if (param === 'week') {
      return 'Semanal'
    } else if (param === 'day') {
      return 'Diario'
    } else if (param === 'bimonthly') {
      return 'Bimestral'
    } else if (param === 'quarterly') {
      return 'Trimestral'
    } else if (param === 'semiannual') {
      return 'Semestral'
    } else {
      return ''
    }
  }

  async handleSelect(index, id, product) {
    let { quotation } = this.props;
    let {confirmedNavigation} = this.state; 

    if(!quotation[index].birth_date)  { 
      await this.setState({
        date: new Date(),
        openAlert: true,
        messageAlert: 'Debe indicar la fecha de nacimiento del beneficiario'
      })}
    else {
      quotation[index].product_id = id;
      quotation[index].product_type = product;
      quotation[index].products = this.state.method.plans;
      await this.props.handleSave('quotation', quotation);
      await this.setState({ quotation });

      setTimeout(() => {
        if(confirmedNavigation){
          this.handleSubmit();
        } else {
          this.handleSubmit();
          this.startTimer();
        }
      }, 3);
    }
  }

  async handleSubmit() {
    let { quotation, quotationForSend, share_link_discount, isUser } = this.props;

    const intervals = [
      'quarterly',
      'month',
      'semiannual',
      'year',
    ]

    const beneficiaries = quotation.filter(q => q.birth_date && q.product_id);
    let percent_off = quotationForSend?.percent_off !== 'default' ? quotationForSend.percent_off : 0;
    
    if(quotationForSend?.percent_off === 'OTRO' && quotationForSend?.other_percent) percent_off = parseInt(quotationForSend.other_percent);

    let dataToSend = { intervals, beneficiaries, percent_off };
    if(!isUser) dataToSend = { ...dataToSend, link_rrss_discount: share_link_discount }

    const result = await this.props.productsQuotation({ ...dataToSend });
    if (result.success === true) {
      
      if(this.props.fromHome && this.state.eventSended === false) {
        this.setState({ eventSended: true });
        const event_quotation = {
          dataLayer: {
            event: 'evento-cotizacion-home-dos',
          },
        }
        TagManager.dataLayer(event_quotation);
      }

      await this.setState({
        allIntervals: result.intervals,
        intervals: result.intervals[this.props.isoCode],
        percent_off: result.percent_off, 
      })
      await this.props.handleSave('quotation', quotation);

      if(Object.keys(this.state.selectedInterval).length === 0 && result?.intervals[this.props.isoCode]?.year){
        await this.handleSelectInterval(result.intervals[this.props.isoCode].year)
      }else if(Object.keys(this.state.selectedInterval).length > 0 ){
        let newSelectedInterval = result.intervals[this.props.isoCode][this.state.selectedInterval.recurrent_interval] 
        await this.handleSelectInterval(newSelectedInterval)
      }

    } else {
      this.setState({ ...result });
    }

  }

  async handleAddBeneficiary() {
    let quotation = this.props.quotation;
    quotation.push(this.structure(quotation, this.props.products));
    await this.setState({quotation});
    await this.props.handleSave('quotation', quotation);
  }

  handleDelete = async (index) => {
    let quotation = this.props.quotation;
    quotation.splice(index, 1);
    await this.setState({ quotation });
    this.props.handleSave('quotation', quotation);

    if(quotation.every(q =>!q.birth_date || !q.product_id )){
      await this.setState({
        intervals: [],
        selectedInterval: {},
      })
    }else {
      this.handleSubmit();
    }
  }

  handleRedirect() {
    let quotation = this.props.quotation;
    const notValid = quotation.find((b) => (b.product_id === ''));
    if (notValid) {
      this.setState({
        date: new Date(),
        openAlert: true,
        messageAlert: 'Debe seleccionar un plan por cada beneficiario'
      })
    } else {
      if (this.props.token && this.props.user_type === 1) {
        Router.push({
            pathname: '/affiliation',
            query: {
              quotation_beneficiaries: JSON.stringify(this.props.quotation),
              signIn: true,
              quotation: true
            }
          },
          '/comenzar-afiliacion');
      } else if (this.props.isAlly === true) {
        Router.push({
            pathname: '/affiliation',
            query: {
              quotation_beneficiaries: JSON.stringify(this.props.quotation),
              signIn: true,
              is_new_beneficiary: true,
              is_ally_client: this.props.isAlly,
              quotation: true
            }
          },
          '/comenzar-afiliacion'
        );
      } else if (this.props.fromLanding) {
        this.goToAffiliation();
      } else {
        /** TAG MANAGER EVENT */
        const event4 = {
          dataLayer: {
            event: 'evento-continuar-afiliacion-paso-cuatro'
          }
        };
        TagManager.dataLayer(event4);

        const tagManagerArgs = {
          dataLayer: {
            event: 'pageview',
            pageTitle: 'asistensi es la mejor póliza de seguro para emergencias médicas',
            pageUrl: '/registro-cliente'
          },
        }
        TagManager.dataLayer(tagManagerArgs);
        /** End Tag Manager events */

        this.props.setMembershipOrigin('quotation');
        this.goToAffiliation();
      }
    }
  }

  goToAffiliation = () => {
    Router.push({ pathname: '/affiliation', query: { quotation_beneficiaries: JSON.stringify(this.props.quotation), signIn: true, quotation: true  }}, '/comenzar-afiliacion');
  }

  handleSharedQuotation = (isUser = false) => {
    let quotation = this.props.quotation;
    let quotationForSend = this.props.quotationForSend;
    const notValid = quotation.find((b) => (b.product_id === ''));
    if(notValid) {
      this.setState({
        openAlert: true,
        messageAlert: 'Debe seleccionar un plan por cada beneficiario'
      })
    } else {
      quotationForSend.parients = [ ...quotation ];
      this.props.saveQuotation(quotationForSend);

      const pathname = '/quotation/sharedQuotation';
      const asPath = '/compartir-cotizacion';

      if(isUser) {
        if(this.props.isAdmin) {
          Router.push(
            { pathname, query: { isUser: true, isAdmin: true }},
            asPath
          ).then(() => window.scrollTo(0, 0));
        } else {
          Router.push(
            { pathname, query: { isUser: true }},
            asPath
          ).then(() => window.scrollTo(0, 0));
        }
      } else if (this.props.fromLanding) {
        Router.push(
          { pathname, query: { isLanding: true }},
          asPath
        ).then(() => window.scrollTo(0, 0));
      } else {
        // FOR TAG MANAGER EVENT
        const tagManagerArgs = { dataLayer: { event: 'evento-guardar-cotizacion-paso-cinco' } };
        TagManager.dataLayer(tagManagerArgs);
        Router.push(pathname, asPath).then(() => window.scrollTo(0, 0));
      }
    }
  }

  handleDate = (date, index, name) => {
    let quotation = this.props.quotation;
    quotation[index][name] = date;
    this.props.handleSave('quotation', quotation);
    this.setState({ quotation });
  }

  handleSelectInterval = async (interval) => {
    let quotation = this.props.quotation;
    let emptyBeneficiaries = quotation.filter(q => !q.product_id && !q.birth_date);
    quotation = [...interval.beneficiaries, ...emptyBeneficiaries]
    
    await this.setState({ selectedInterval: interval, quotation });
    await this.props.handleSave('quotation', quotation);
  }

  handleClose = () => this.setState({ openAlert: false });

  formatFloat = (num) => {
    const isInt = (n) => Number(n) === n && n % 1 === 0;
    const isFloat = (n) => Number(n) === n && n % 1 !== 0;
    if (isInt(num) || isFloat(num)) {
      return parseFloat(num).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseFloat(num.toString().replace(/[^0-9,]+/g, '')).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  showDialog = async (location) => {
    if(!this.props.isUser && !this.props.fromLanding) this.setState({ openDialog: true, lastLocation: location })
  }

  closeDialog = async (callback) => {
    this.stopTimer()
    await this.setState({
      openDialog: false,
      confirmedNavigation: true,
    }, callback)
  }

  render() {
    const { 
      quotation, 
    } = this.props;
    const { 
      method, 
      openAlert, 
      messageAlert, 
      percent_off, 
      intervals, 
      selectedInterval
    } = this.state;

    return (
      <div className="w-full">
        <style global jsx>
          {`
            .table-responsive__row:nth-child(2), 
            .table-responsive__row:nth-child(3) {
              padding: 18px 22px;
              border-bottom: 1px solid #e6ebf1;
            }
            .table-responsive__row:nth-child(2) label, 
            .table-responsive__row:nth-child(3) label {
              height: 45px;
              margin-top: -14px;
              margin-bottom: 0px;
            }
            .table-responsive__row:nth-child(4) {
              padding: 18px 22px;
            }
            .table-responsive__row:nth-last-child(1) {
              border-bottom: 0 solid #e6ebf1;
            }
            .before-price {
              top: -24px
            }
            .mt-n-2 {
              margin-top: -2px
            }
            .fw-table {
              width: calc(100% - 2px)
            }
          `}
        </style>
        <div className="container">
          <div className="flex justify-between mt-16 items-center "> 
            <span></span>
            <h2 className="text-center font-poppins text-purple-500 text-base font-bold">Selecciona el producto para cada uno de tus beneficiarios:</h2>
            <SelectCurrency/>
          </div>

          <div className="w-full flex flex-col mt-8">
            <table className="w-full hidden md:table">
              <tbody className="w-full">
                <tr className="w-full">
                  {
                    isArray(quotation) && quotation.map((q, index) => (
                      <td className="flex items-center justify-between py-2 px-6 fw-table mx-auto bg-white border border-gray-300 mt-n-2" key={index}>
                        <div className="flex items-center mr-4">
                          <div className="text-base font-bold text-gray-800 mr-2">Beneficiario N° {index+1}</div>
                          {
                            q.birth_date !== null
                            ? <div className="text-base text-gray-600">{format(q.birth_date)}</div>
                            : <DateInput
                                key={index}
                                clearable={true}
                                name="birth_date"
                                required={false}
                                placeholder="F. Nac."
                                format="dd MMM yyyy"
                                maxDate={new Date()}
                                value={q.birth_date}
                                addClass="w-24 mt-2"
                                onChange={date => this.handleDate(date, index, 'birth_date')}
                                readOnly={true}
                              />
                          }
                        </div>
                        {
                          method.plans && method.plans.map((product, i) => (
                            <div key={i} className="mr-4">
                              <FormControlLabel
                                name="product"
                                value={String(product._id)}
                                control={<Radio value={String(product._id)} 
                                className={q.product_id === product._id ? 'checkBoxActive' : 'checkBox'} checked={q.product_id === product._id ? true : false} />}
                                onChange={() => this.handleSelect(index, product._id, product)}
                                label={<span className={`text-gray-800 text-base ${q.product_id === product._id ? 'font-bold' : 'font-normal'}`}>{product.name}</span>}
                                labelPlacement="end"
                              />
                            </div>
                          ))
                        }
                        <div className="text-base lh-163 mr-4">
                          { q.price
                            ? <span className="text-gray-800">{this.props.isoCode.toUpperCase()}.{q.price}</span> 
                            : <span className="text-gray-600">{this.props.isoCode.toUpperCase()}0</span> 
                          }
                        </div>
                        <IconButton
                          className="focus:outline-none hover:bg-pink-200"
                          onClick={() => this.handleDelete(index)}
                          disabled={isArray(quotation) && quotation.length === 1 ? true : false}
                        >
                          <DeleteIcon className={isArray(quotation) && quotation.length === 1 ? 'text-gray-700' : 'text-gray-600'}/>
                        </IconButton>
                      </td>
                    ))
                  }
                </tr>
                <tr className="w-full">
                  <td className="w-full">
                    <button className="flex justify-center items-center button-add-beneficiary mt-n-2" onClick={() => this.handleAddBeneficiary()}>
                      AÑADIR OTRO BENEFICIARIO
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* RESPONSIVE CARD */}
            {
              isArray(quotation) && quotation.map((q, index) => (
                <div className="w-full border border-gray-300 mb-4 md:hidden" key={index}>
                  <div className="py-2 pr-4 pl-6 flex justify-between items-center border-b border-gray-300 bg-gray-100">
                    <span className="text-gray-800 text-base font-bold">Beneficiario N° {index+1}</span>
                    <IconButton
                      className="focus:outline-none hover:bg-pink-200"
                      onClick={() => this.handleDelete(index)}
                      disabled={isArray(quotation) && quotation.length === 1 ? true : false}
                    >
                      <DeleteIcon className={isArray(quotation) && quotation.length === 1 ? 'text-gray-700' : 'text-gray-600'}/>
                    </IconButton>
                  </div>
                  <div className="text-gray-800 text-base flex items-center justify-between table-responsive__row">
                    <span className="font-bold">Fecha de nacimiento</span>
                    {
                      q.birth_date !== null
                      ? <span className="text-gray-600">{format(q.birth_date)}</span>
                      : <DateInput
                          key={index}
                          clearable={true}
                          name="birth_date"
                          required={false}
                          placeholder="F. Nac."
                          format="dd MMM yyyy"
                          maxDate={new Date()}
                          value={q.birth_date}
                          addClass="w-24 mt-2"
                          onChange={date => this.handleDate(date, index, 'birth_date')}
                          readOnly={true}
                        />
                    }
                  </div>
                  <div className="text-gray-800 text-base flex justify-between table-responsive__row">
                    <span className="font-bold">Plan</span>
                    <span className="flex flex-col items-end">
                      {
                        method.plans && method.plans.map((product, i) => (
                          <span key={i} className="mb-2">
                            <FormControlLabel
                              name="product"
                              value={String(product._id)}
                              control={<Radio value={String(product._id)} className={q.product_id === product._id ? 'checkBoxActive' : 'checkBox'} checked={q.product_id === product._id ? true : false} />}
                              onChange={() => this.handleSelect(index, product._id, product)}
                              label={<span className={`text-gray-800 text-base ${q.product_id === product._id ? 'font-bold' : 'font-normal'}`}>{product.name}</span>}
                              labelPlacement="start"
                            />
                          </span>
                        ))
                      }
                    </span>
                  </div>
                  <div className="text-gray-800 text-base flex items-center justify-between table-responsive__row">
                    <span className="font-bold">Valor</span>
                    { q.price 
                      ? <span className="text-gray-800">{this.props.isoCode.toUpperCase()}{q.price}</span> 
                      : <span className="text-gray-600">{this.props.isoCode.toUpperCase()}0</span> }
                  </div>
                </div>
              ))
            }
            <button className="flex justify-center items-center button-add-beneficiary md:hidden" onClick={() => this.handleAddBeneficiary()}>
              AÑADIR OTRO BENEFICIARIO
            </button>
          </div>
          
        </div>
        <div className="mt-12">
          <SelectCurrency/>
        </div>
        <PaymentFrecuency 
          selectedInterval={selectedInterval}
          frecuency={this.frecuency}
          intervals={intervals}
          percent_off={percent_off}
          formatFloat={this.formatFloat}
          handleSelectInterval={this.handleSelectInterval}
          isoCode={this.props.isoCode}
        />
        
        {
            this.props.isUser
            ? <div className="flex justify-center items-center mt-12">
                <button className="primary-button max-w-small sm:max-w-none" onClick={() => this.handleSharedQuotation(true)}>
                  <span className="sm:hidden">Enviar</span><span className="hidden sm:inline">Enviar cotización</span>
                </button>
              </div>
            : <div className={`flex mt-12 justify-between`}>
                <button id="guardar-afiliacion-paso4" className="secondary-button max-w-small sm:max-w-none" onClick={() => this.handleSharedQuotation()}>
                  <span className="sm:hidden">Guardar</span><span className="hidden sm:inline">Guardar cotización</span>
                </button>
                <button id="continuar-afiliacion-paso3" className="primary-button max-w-small sm:max-w-none" onClick={() => this.handleRedirect()}>
                  <span className="sm:hidden">¡Contratación!</span><span className="hidden sm:inline">Continuar contratación</span>
                </button>
              </div>
          }

        {
          this.state.openDialog === true &&
          <SharedDialog open={this.state.openDialog} handleClose={this.closeDialog}/>
        }
        <SnackBar key={this.state.date}  openAlert={openAlert} messageAlert={messageAlert} handleClose={this.handleClose}/>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const isoCode = state.currencies.get('isoCode');
  return {
    isoCode,

  }
}


export default connect(mapStateToProps, null) (QuotationBeneficiaries);
